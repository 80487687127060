
                                @use "~Styles/includes/mixins";
                                @use "~Styles/includes/variables";
                            
$ls: lecture_series;
$lsi: lecture_series_item;

.#{$ls} {
    margin-bottom: 48px;

    &__title {
        font-family: variables.$fontPrimary;
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: -0.02em;
        color: #2c2c2e;
    }

    &__list {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        margin: 24px 0 0;

        @include mixins.min-screen(770px) {
            row-gap: 24px;
        }
    }
}

.#{$lsi} {
    font-family: variables.$fontPrimary;

    &:not(:last-child) {
        padding: 0 0 12px;
        border-bottom: 1px solid #F5F5F5;

        @include mixins.min-screen(770px) {
            padding: 0 0 48px;
        }
    }

    &__link {
        display: flex;
        flex-direction: column;
        row-gap: 36px;
        text-decoration: none !important;

        @include mixins.min-screen(770px) {
            flex-direction: row;
            row-gap: 0;
            column-gap: 24px;
        }
    }

    &__figure {
        align-self: flex-start;
        width: 100%;
        position: relative;
        margin: 0;
        border: 1px solid rgba(#000, 0.1);

        @include mixins.min-screen(770px) {
            max-width: 298px;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }

        &::before {
            content: " ";
            position: absolute;
            top: 100%;
            left: 16px;
            right: 16px;
            height: 12px;
            background-color: #E5E5EA;
        }

        &::after {
            content: " ";
            position: absolute;
            top: calc(100% + 12px);
            left: 32px;
            right: 32px;
            height: 12px;
            background-color: #F2F2F7;
        }
    }

    &__video_count {
        position: absolute;
        bottom: 12px;
        right: 12px;
        z-index: 2;
        height: 22px;
        padding: 0 8px;
        border-radius: 10px;
        margin: 0;
        background-color: rgba(#050505, 0.65);
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        color: #fff;
    }

    &__info {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        width: 100%;
    }

    &__info_header {
        display: flex;
        justify-content: space-between;

        p {
            margin: 0;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.005em;
            color: #717175;
        }
    }

    &__title {
        margin: 0;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.005em;
        color: #2c2c2e;
    }

    &__doctors {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #717175;
    }

    &__metadata {
        display: flex;
        align-items: center;
        column-gap: 8px;
        margin: 4px 0 0;
    }

    &__views,
    &__hours {
        display: inline-flex;
        align-items: center;
        column-gap: 8px;
        height: 28px;
        padding: 0 8px;
        border-radius: 8px;
        background-color: #F2F2F7;
        color: #2c2c2e;

        .icon {
            font-size: 14px;
        }

        span {
            font-size: 14px;
            line-height: 1;
            letter-spacing: 0.005em;
        }
    }
}
